var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-container"},[_c('div',{staticClass:"banner"},[_c('el-carousel',{staticStyle:{"height":"28vw"},attrs:{"interval":5000,"arrow":"always","autoplay":false}},_vm._l((4),function(item){return _c('el-carousel-item',{key:item,staticStyle:{"height":"28vw"}},[_c('div',{staticClass:"banner_min"})])}),1)],1),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"news"},[_c('div',{staticClass:"news_min"},[_vm._m(4),_c('div',{staticClass:"news_con"},[_c('div',{staticClass:"news_item textdong animate__fadeInUp",on:{"click":_vm.onNews}},[_vm._m(5),_vm._m(6)]),_c('div',{staticClass:"news_item textdong animate__fadeInUp",on:{"click":_vm.onNews}},[_vm._m(7),_vm._m(8)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about"},[_c('div',{staticClass:"about_min"},[_c('div',{staticClass:"about_left"},[_c('div',{staticClass:"about_title textdong animate__animated animate__fadeInDown"},[_vm._v("关于易橙"),_c('span',[_vm._v(" / ABOUT YICHENG")])]),_c('p',{staticClass:"textdong animate__bounceInLeft"},[_vm._v("易橙学堂是在线教育行业的领跑者，多年来坚持初心做良心企业，真正的帮助用户得到更好的提升，凭借”为学员服务、为学员负责”的教育理念，打造一站式课堂，为广大用户提供优质的教学服务，获得广大用户好评。")]),_c('p',{staticClass:"textdong animate__bounceInLeft"},[_vm._v("易橙学堂屡次荣获包括：影响力在线教育品牌、行业标杆集团、公众信赖的技能培训品牌等荣誉")])]),_c('div',{staticClass:"about_right"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/img/logo.jpg"),"alt":""}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"adving"},[_c('div',{staticClass:"adv_ming"},[_c('div',{staticClass:"about_title textdong animate__fadeInDown",staticStyle:{"color":"white"}},[_vm._v("课程优势"),_c('span',{staticStyle:{"color":"white"}},[_vm._v(" / ADVANTAGE")])]),_c('div',{staticClass:"adv_min animate__fadeInUpBig textdong"},[_c('div',{staticClass:"adv-item"},[_c('img',{attrs:{"src":require("@/assets/img/3.jpg"),"alt":""}}),_c('div',[_vm._v("在线课堂")]),_c('p',[_vm._v("随时随地上课，不受时空限制；网络互动，学习更自由。 ")])]),_c('div',{staticClass:"adv-item"},[_c('img',{attrs:{"src":require("@/assets/img/item.jpg"),"alt":""}}),_c('div',[_vm._v("科技赋能")]),_c('p',[_vm._v("让在线学习更加便捷高效，数字化教育，推动教育变革创新。")])]),_c('div',{staticClass:"adv-item"},[_c('img',{attrs:{"src":require("@/assets/img/4.jpg"),"alt":""}}),_c('div',[_vm._v("一站式服务")]),_c('p',[_vm._v("为在线学习提供全方位支持，让学生获得更好的学习体验。")])]),_c('div',{staticClass:"adv-item"},[_c('img',{attrs:{"src":require("@/assets/img/4.jpg"),"alt":""}}),_c('div',[_vm._v("师资团队 ")]),_c('p',[_vm._v("一流教学资源，专业、成熟的师资团队执教。为学生提供全面的学习指导。")])]),_c('div',{staticClass:"adv-item"},[_c('img',{attrs:{"src":require("@/assets/img/5.jpg"),"alt":""}}),_c('div',[_vm._v("全程伴学 ")]),_c('p',[_vm._v("让在线学习更加贴近学生需求，个性化辅导，提升学习效果与体验。")])]),_c('div',{staticClass:"adv-item"},[_c('img',{attrs:{"src":require("@/assets/img/6.jpg"),"alt":""}}),_c('div',[_vm._v("教学理念")]),_c('p',[_vm._v("创新教学理念，提高在线学习的针对性与实用性；灵活多样的教学方式。")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"honour"},[_c('div',{staticClass:"honour_min"},[_c('div',{staticClass:"about_title textdong animate__fadeInDown"},[_vm._v("企业荣誉"),_c('span',[_vm._v(" / HONOUR")])]),_c('div',{staticClass:"hou_banner textdong animate__fadeIn"},[_c('div',{staticClass:"swiper mySwiper",staticStyle:{"width":"100%","overflow":"hidden"}},[_c('div',{staticClass:"swiper-wrapper"},[_c('div',{staticClass:"swiper-slide",attrs:{"id":"muong"}},[_c('img',{staticStyle:{"width":"100%","border-radius":"12px"},attrs:{"src":require("@/assets/img/banner1.jpg"),"alt":""}}),_c('p',{staticClass:"text1"},[_vm._v("2020年度公众信赖财商技能培训品牌")])]),_c('div',{staticClass:"swiper-slide",attrs:{"id":"muong"}},[_c('img',{staticStyle:{"width":"100%","border-radius":"12px"},attrs:{"src":require("@/assets/img/banner2.jpg"),"alt":""}}),_c('p',{staticClass:"text1"},[_vm._v("2021年度影响力在线教育品牌")])]),_c('div',{staticClass:"swiper-slide",attrs:{"id":"muong"}},[_c('img',{staticStyle:{"width":"100%","border-radius":"12px"},attrs:{"src":require("@/assets/img/banner3.jpg"),"alt":""}}),_c('p',{staticClass:"text1"},[_vm._v("2022年度\"腾讯回响中国\"影响力在线教育")])]),_c('div',{staticClass:"swiper-slide",attrs:{"id":"muong"}},[_c('img',{staticStyle:{"width":"100%","border-radius":"12px"},attrs:{"src":require("@/assets/img/banner4.jpg"),"alt":""}}),_c('p',{staticClass:"text1"},[_vm._v("2020年度影响力在线教育品牌")])])])]),_c('div',{staticClass:"swiper-button-next1"},[_c('i',{staticClass:"el-icon-arrow-right icon"})]),_c('div',{staticClass:"swiper-button-prev2"},[_c('i',{staticClass:"el-icon-arrow-left icon"})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"student"},[_c('div',{staticClass:"student_min"},[_c('div',{staticClass:"about_title textdong animate__fadeInDown",staticStyle:{"color":"white"}},[_vm._v("学员口碑"),_c('span',{staticStyle:{"color":"white"}},[_vm._v(" / HIGH OPINION")])]),_c('div',{staticClass:"swiper-container textdong animate__fadeIn",attrs:{"id":"swiper-container1"}},[_c('div',{staticClass:"swiper-wrapper",attrs:{"id":"swiper-wrapper1"}},[_c('div',{staticClass:"swiper-slide item_bg1",staticStyle:{"width":"414px","height":"705px"},attrs:{"id":"swiper-slide"}},[_c('div',{staticClass:"bg"})]),_c('div',{staticClass:"swiper-slide item_bg2",staticStyle:{"width":"414px","height":"705px"},attrs:{"id":"swiper-slide"}},[_c('div',{staticClass:"bg"})]),_c('div',{staticClass:"swiper-slide item_bg3",staticStyle:{"width":"414px","height":"705px"},attrs:{"id":"swiper-slide"}},[_c('div',{staticClass:"bg"})]),_c('div',{staticClass:"swiper-slide item_bg4",staticStyle:{"width":"414px","height":"705px"},attrs:{"id":"swiper-slide"}},[_c('div',{staticClass:"bg"})])])]),_c('div',{staticClass:"swiper-button-prev"},[_c('i',{staticClass:"el-icon-arrow-left icon2",staticStyle:{"border-radius":"50%","padding":"10px","font-weight":"bold"}})]),_c('div',{staticClass:"swiper-button-next"},[_c('i',{staticClass:"el-icon-arrow-right icon2",staticStyle:{"border-radius":"50%","padding":"10px","font-weight":"bold"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about_title textdong animate__fadeInDown"},[_vm._v("易橙动态"),_c('span',[_vm._v(" / NEWS")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news_left"},[_c('img',{staticClass:"reimg",attrs:{"src":require("@/assets/img/news.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news_right"},[_c('p',[_vm._v("2022-12-28")]),_c('p',[_vm._v("易橙学堂荣获腾讯“回响中国”2022年度影响力在线教育品牌")]),_c('p',[_vm._v("12月26日，2022“回响中国”腾讯新闻•教育频道年度高峰论坛在北京成功举行。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news_left"},[_c('img',{staticClass:"reimg",attrs:{"src":require("@/assets/img/news.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news_right"},[_c('p',[_vm._v("2022-12-28")]),_c('p',[_vm._v("易橙学堂荣获腾讯“回响中国”2022年度影响力在线教育品牌")]),_c('p',[_vm._v("12月26日，2022“回响中国”腾讯新闻•教育频道年度高峰论坛在北京成功举行。")])])
}]

export { render, staticRenderFns }