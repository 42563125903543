<template>
  <div class="app-container">
    <div class="banner">
      <el-carousel :interval="5000" arrow="always" :autoplay="false" style="height: 28vw;">
        <el-carousel-item v-for="item in 4" :key="item" style="height: 28vw;">
          <div class="banner_min"></div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="about">
      <div class="about_min">
        <div class="about_left">
          <div class="about_title  textdong animate__animated animate__fadeInDown">关于易橙<span> / ABOUT YICHENG</span></div>
          <p class="textdong  animate__bounceInLeft">易橙学堂是在线教育行业的领跑者，多年来坚持初心做良心企业，真正的帮助用户得到更好的提升，凭借”为学员服务、为学员负责”的教育理念，打造一站式课堂，为广大用户提供优质的教学服务，获得广大用户好评。</p>
          <p class="textdong  animate__bounceInLeft">易橙学堂屡次荣获包括：影响力在线教育品牌、行业标杆集团、公众信赖的技能培训品牌等荣誉</p>
        </div>
        <div class="about_right">
          <div>
            <img src="@/assets/img/logo.jpg" alt="">
          </div>
        </div>
      </div>
    </div>

    <div class="adving">
      <div class="adv_ming">
        <div class="about_title  textdong  animate__fadeInDown" style="color:white;">课程优势<span style="color:white;"> / ADVANTAGE</span></div>
        <div class="adv_min animate__fadeInUpBig textdong">
          <div class="adv-item">
            <img src="@/assets/img/3.jpg" alt="">
            <div>在线课堂</div>
            <p>随时随地上课，不受时空限制；网络互动，学习更自由。 </p>
          </div>
          <div class="adv-item">
            <img src="@/assets/img/item.jpg" alt="">
            <div>科技赋能</div>
            <p>让在线学习更加便捷高效，数字化教育，推动教育变革创新。</p>
          </div>
          <div class="adv-item">
            <img src="@/assets/img/4.jpg" alt="">
            <div>一站式服务</div>
            <p>为在线学习提供全方位支持，让学生获得更好的学习体验。</p>
          </div>
          <div class="adv-item">
            <img src="@/assets/img/4.jpg" alt="">
            <div>师资团队 </div>
            <p>一流教学资源，专业、成熟的师资团队执教。为学生提供全面的学习指导。</p>
          </div>
          <div class="adv-item">
            <img src="@/assets/img/5.jpg" alt="">
            <div>全程伴学 </div>
            <p>让在线学习更加贴近学生需求，个性化辅导，提升学习效果与体验。</p>
          </div>
          <div class="adv-item">
            <img src="@/assets/img/6.jpg" alt="">
            <div>教学理念</div>
            <p>创新教学理念，提高在线学习的针对性与实用性；灵活多样的教学方式。</p>
          </div>
        </div>
      </div>
    </div>

    <!-- 企业荣誉 -->
    <div class="honour">
      <div class="honour_min">
        <div class="about_title  textdong  animate__fadeInDown">企业荣誉<span> / HONOUR</span></div>
        <div class="hou_banner textdong animate__fadeIn">
          <div class="swiper mySwiper" style="width: 100%;  overflow: hidden;">
            <div class="swiper-wrapper">
              <div class="swiper-slide" id="muong">
                <img src="@/assets/img/banner1.jpg" alt="" style="width: 100%;border-radius: 12px;">
                <p class="text1">2020年度公众信赖财商技能培训品牌</p>
              </div>
              <div class="swiper-slide" id="muong" >
                <img src="@/assets/img/banner2.jpg" alt="" style="width: 100%;border-radius: 12px;">
                <p class="text1">2021年度影响力在线教育品牌</p>
              </div>
              <div class="swiper-slide" id="muong" >
                <img src="@/assets/img/banner3.jpg" alt="" style="width: 100%;border-radius: 12px;">
                <p class="text1">2022年度"腾讯回响中国"影响力在线教育</p>
              </div>
              <div class="swiper-slide" id="muong" >
                <img src="@/assets/img/banner4.jpg" alt="" style="width: 100%;border-radius: 12px;">
                <p class="text1">2020年度影响力在线教育品牌</p>
              </div>
            </div>
          </div>
          <div class="swiper-button-next1">
            <i class="el-icon-arrow-right icon"></i>
          </div>
          <div class="swiper-button-prev2">
            <i class="el-icon-arrow-left icon"></i>
          </div>
        </div>
      </div>
    </div>

    <!-- 学员口碑 -->
    <div class="student">
      <div class="student_min">
        <div class="about_title  textdong  animate__fadeInDown" style="color:white;">学员口碑<span style="color:white;"> / HIGH OPINION</span></div>
        <div class="swiper-container textdong animate__fadeIn" id="swiper-container1">
          <div class="swiper-wrapper" id="swiper-wrapper1">
            <div class="swiper-slide item_bg1" id="swiper-slide" style="width: 414px;height: 705px;">
              <div class="bg"></div>
            </div>
            <div class="swiper-slide item_bg2" id="swiper-slide" style="width: 414px;height: 705px;">
              <div class="bg"></div>
            </div>
            <div class="swiper-slide item_bg3" id="swiper-slide" style="width: 414px;height: 705px;">
              <div class="bg"></div>
            </div>
            <div class="swiper-slide item_bg4" id="swiper-slide" style="width: 414px;height: 705px;">
              <div class="bg"></div>
            </div>
          </div>
        </div>
        <div class="swiper-button-prev">
          <i class="el-icon-arrow-left icon2" style=" border-radius: 50%;padding: 10px;font-weight: bold;"></i>
        </div>
        <div class="swiper-button-next">
          <i class="el-icon-arrow-right icon2" style=" border-radius: 50%;padding: 10px;font-weight: bold;"></i>
        </div>
      </div>
    </div>

    <!-- 易橙动态 -->
    <div class="news">
      <div class="news_min">
        <div class="about_title  textdong  animate__fadeInDown">易橙动态<span> / NEWS</span></div>
        <div class="news_con">
          <div class="news_item textdong animate__fadeInUp" @click="onNews">
            <div class="news_left">
              <img src="@/assets/img/news.png" alt="" class="reimg">
            </div>
            <div class="news_right">
              <p>2022-12-28</p>
              <p>易橙学堂荣获腾讯“回响中国”2022年度影响力在线教育品牌</p>
              <p>12月26日，2022“回响中国”腾讯新闻•教育频道年度高峰论坛在北京成功举行。</p>
            </div>
          </div>
          <div class="news_item textdong animate__fadeInUp" @click="onNews">
            <div class="news_left">
              <img src="@/assets/img/news.png" alt="" class="reimg">
            </div>
            <div class="news_right">
              <p>2022-12-28</p>
              <p>易橙学堂荣获腾讯“回响中国”2022年度影响力在线教育品牌</p>
              <p>12月26日，2022“回响中国”腾讯新闻•教育频道年度高峰论坛在北京成功举行。</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/dist/css/swiper.css";
import "swiper/dist/js/swiper.min";
import Wow from "wow.js";
export default {
  name: "Home",
  data() {
    return {
      imgurl: 'https://image.xicaishe.com/1678952050220_RN8dKH.jpg'
    };
  },
  methods: {
    onNews(){
      window.open('https://mp.weixin.qq.com/s/gECLWPGE55u4ZuMOZ70R6w')
    }
  },
  mounted() {
    const wow = new Wow({
      // 对所有拥有该class的元素挂载动画
      boxClass: 'textdong',
      // 需要挂载的动画元素
      animateClass: "animate__animated",
      // 偏移值（即偏移一定距离后再执行动画，推荐设置！）
      offset: 100,
      // 移动设备是否生效
      mobile: true,
      // 是否支持异步
      live: true,
    });
    wow.init();

    new Swiper(".mySwiper", {
      slidesPerView: 2,
      spaceBetween: 30,
      slidesPerGroup: 2,
      loop: true,
      navigation: {
        nextEl: ".swiper-button-next1",
        prevEl: ".swiper-button-prev2",
      },
    });

    new Swiper(".swiper-container", {
      slidesPerView: 'auto', //每个块是不是自适应大学
      spaceBetween: 12, //每个块之前的大小
      centeredSlides: true, //剧中
      slideToClickedSlide: true,
      loopedSlides: 7, //重复值
      grabCursor: true,
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },

    });

  }
};
</script>

<style lang="scss" scoped>
.text1{
  width: 100%;
  text-align: center;
  font-size: 16px;
  line-height: 40px;
  color: #262626;
  font-weight: bold;
}
#muong ::after{
  content: '';
  background: rgba(0, 0, 0, 0);
}
.swiper-button-next1 {
  width: 100px;
  height: 40px;
  position: absolute;
  top: 40%;
  right: -160px;
  z-index: 99999;
  font-size: 60px;
}
#mycoolor :hover{
  color: red;
}
.icon{
  color: #999;
}
.icon:hover {
  color: rgba(237,109,51,1);
}
.icon2{
  border: 2px #999 solid;
  color: #999;
}
.icon2:hover {
  background-color: rgba(237,109,51,1);
  border: 2px rgba(237,109,51,1) solid;
  color: white;
}
.swiper-button-prev2 {
  width: 100px;
  height: 40px;
  position: absolute;
  top: 40%;
  left: -100px;
  z-index: 99999;
  font-size: 60px;
}
.student_min /deep/ .swiper-button-prev {
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0);
  position: absolute;
  top: 50%;
  left: -60px;
}

.student_min /deep/ .swiper-button-next {
  position: absolute;
  top: 50%;
  right: -60px;
  background: rgba(0, 0, 0, 0);
}

.swiper {
  --swiper-theme-color: #ff6600;
  /* 设置Swiper风格 */
  --swiper-navigation-color: #00ff33;
  /* 单独设置按钮颜色 */
  --swiper-navigation-size: 30px;
  /* 设置按钮大小 */
}
.item_bg1{
  background-image: url('../../assets/img/item1.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.item_bg2{
  background-image: url('../../assets/img/item2.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.item_bg3{
  background-image: url('../../assets/img/item3.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.item_bg4{
  background-image: url('../../assets/img/item4.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.item_bg5{
  background-image: url('../../assets/img/item5.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
#swiper-slide {
  text-align: center;
  font-size: 18px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  transition: 300ms;
  transform: scale(0.8);
}
.swiper-slide ::after {
  content: '';
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: .4);
  position: absolute;
  top: 0px;
  left: 0px;
}
.swiper-slide-active ::after {
  content: '';
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0);
  position: absolute;
  top: 0px;
  left: 0px;
}
#swiper-wrapper1 /deep/ .swiper-slide-active,
.swiper-slide-duplicate-active {
  transform: scale(1);
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}
.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}
.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
.el-carousel__indicator {
  border-radius: 50%;
}
.banner {
  width: 100%;
  min-width: 1200px;
}
.banner_min {
  width: 100%;
  height: 28vw;
  background-image: url('https://image.xicaishe.com/1678952050220_RN8dKH.jpg');
  background-size: 100%;
  background-repeat: no-repeat;
}
.banner /deep/ .el-carousel__button {
  width: 10px;
  height: 10px;
  background: rgba($color: #FFFFFF, $alpha: 0.54);
  border-radius: 50%;
}
.banner /deep/ .el-carousel__container {
  height: 28vw;
}
.about {
  width: 100%;
  height: 360px;
  padding-bottom: 80px;
  background: white;
  .about_min {
    width: 70%;
    min-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .about_left {
      width: 58%;
      >p {
        font-size: 16px;
        line-height: 40px;
        color: rgba(131, 131, 131, 1);
      }
      >p:nth-of-type(2) {
        color: #545454;
        font-weight: bold;
      }
    }
    .about_right {
      width: 40%;
      display: flex;
      align-items: center;
      height: 360px;
      >img {
        width: 360px;
        float: right;
      }
    }
  }
}
.about_title {
  font-size: 40px;
  font-weight: bold;
  padding-top: 60px;
  padding-bottom: 40px;
  >span {
    font-size: 14px;
    color: #262626;
  }
}
.adving {
  width: 100%;
  background: rgba(237, 109, 51, 1);
  padding-bottom: 80px;
  overflow: hidden;
  .adv_ming {
    width: 70%;
    min-width: 1200px;
    margin: 0 auto;
  }
  .adv_min {
    width: 100%;
    max-width: 1200px;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    flex-wrap: wrap;
    .adv-item:hover {
      -webkit-transform: scale(1.1);
      transform: scale(1.1)
    }
    .adv-item {
      display: inline-block;
      vertical-align: middle;
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      box-shadow: 0 0 1px rgba(0, 0, 0, 0);
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-property: transform;
      transition-property: transform;
      width: 353px;
      height: 340px;
      background: #FFFFFF;
      box-shadow: 0px 10px 10px 0px rgba(171, 58, 0, 0.1);
      border-radius: 8px;
      margin-top: 40px;
      >img {
        width: 160px;
        margin: 0 auto;
        padding-top: 20px;
        display: block;
      }
      >div {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        color: #343434;
        padding-top: 15px;
      }
      >p {
        text-align: center;
        font-size: 14px;
        line-height: 30px;
        width: 80%;
        margin: 0 auto;
        padding-top: 40px;
      }
    }
  }
}
.honour {
  width: 100%;
  background-color: white;
  .honour_min {
    width: 70%;
    min-width: 1200px;
    margin: 0 auto;
    height: 600px;
    background: white;
    overflow: hidden;
    .hou_banner {
      width: 800px;
      margin: 30px auto;
      margin-top: 60px;
      position: relative;
    }
  }
}
.student {
  width: 100%;
  min-width: 1200px;
  background: url('https://image.xicaishe.com/1679023152769_ORmZ6q.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 40vw;
  min-height: 960px;
  .student_min {
    width: 70%;
    min-width: 1200px;
    margin: 0 auto;
    padding-bottom: 100px;
    min-height: 960px;
    height: 40vw;
    position: relative;
  }
}
.student /deep/ .el-carousel__indicators {
  display: none;
}
.news {
  width: 100%;
  background-color: white;
  .news_min {
    width: 70%;
    min-width: 1200px;
    margin: 0 auto;
    background: white;
    padding-bottom: 70px;
    .news_con {
      width: 100%;
      display: flex;
      justify-content: space-between;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      .news_item:nth-child(even) {
        margin-left: 20px;
      }
      .news_item {
        width: 45%;
        display: flex;
        margin-top: 40px;
        cursor: pointer;
        >.news_left {
          >img{
            width: 250px;
            border-radius: 10px;
          }
        }
        >.news_right {
          min-width: 360px;
          padding-left: 20px;
          >p:nth-of-type(1) {
            color: #545454;
            font-size: 14px;
            line-height: 30px;
          }
          >p:nth-of-type(2) {
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            color: #262626;
            font-weight: bold;
            line-height: 30px;
            font-size: 24px;
          }
          >p:nth-of-type(3) {
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            color: #696969;
            font-size: 12px;
            line-height: 20px;
            margin-top: 10px;
          }
        }
      }
    }
  }
}
</style>
